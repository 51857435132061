a {
  text-decoration: none !important;
  color: black !important;
}

.breadcrumb-item a {
  color: #976f32 !important;
  font-weight: 600;
}

.top-bar {
  background-color: #976f32;
  color: white;
}

.top-bar .nav-link {
  color: white !important;
}

.search {
  padding: 3px 10px !important;
  margin-top: 4px;
  width: 300px !important;
}

.logo {
  height: 60px;
}

.nft-image {
  height: 100px;
  object-fit: cover;
  width: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.nft-title {
  font-size: 14px;
  line-height: 1.43;
}

.nft-edition {
  color: #a2a2b8;
  line-height: 1.43;
  font-size: 14px;
}

footer .widget-title {
  color: #976f32;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 1.9rem;
}

footer .nav-list {
  list-style: none;
}

footer .nav-list li {
  text-decoration: none;
}